import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Module */
import { HomeModule } from '@modules/home/home.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('modules/public/public-routing.module').then((m) => m.PublicRoutingModule),
  },
  {
    path: 'dashboard',
    redirectTo: '/auth/login',
  },
  {
    path: 'portal',
    redirectTo: '/auth/login',
  },
  {
    path: 'login',
    redirectTo: '/auth/login',
  },
  {
    path: 'signup/student',
    redirectTo: '/auth/signup/student',
  },
  {
    path: 'auth',
    loadChildren: () => import('modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
  },
  {
    path: 'error',
    loadChildren: () => import('modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('modules/admin-portal/admin-portal-routing.module').then((m) => m.AdminPortalRoutingModule),
  },
  {
    path: 'teacher',
    loadChildren: () => import('modules/teacher-portal/teacher-portal-routing.module').then((m) => m.TeacherPortalRoutingModule),
  },
  {
    path: 'student',
    loadChildren: () => import('modules/student-portal/student-portal-routing.module').then((m) => m.StudentPortalRoutingModule),
  },
  {
    path: 'district',
    loadChildren: () => import('modules/district-portal/district-portal-routing.module').then((m) => m.DistrictPortalRoutingModule),
  },
  {
    path: 'user',
    loadChildren: () => import('modules/user-portal/user-portal-routing.module').then((m) => m.UserPortalRoutingModule),
  },
  {
    path: 'sciops',
    loadChildren: () => import('modules/sciops/sciops-routing.module').then((m) => m.SciopsRoutingModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
  },
];

@NgModule({
  imports: [HomeModule, RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
